<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <div v-if="permission.read_perm == 1">
        <v-container
          v-if="form !== null"
          style="
            padding: 0 0 20px 0;
            margin: auto;
            border-radius: 5px;
            background: white;
          "
        >
          <v-form
            ref="entryForm"
            @submit.prevent="submitOvertime()"
            lazy-validation
            :disabled="loading"
          >
            <v-card
              tile
              flat
              style="
                border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                position: sticky;
                top: 60px;
                background: white;
                z-index: 5;
              "
            >
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 10px;
                "
              >
                <v-toolbar-title class="overline" style="position: relative">
                  <p
                    :style="`font-size:${
                      wWidth > 780 ? '20px' : '3vw'
                    }; margin: 0;`"
                  >
                    Detail Pengajuan Lembur
                    <v-tooltip bottom color="grey">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          x-small
                          text
                          icon
                          color="grey darken-2"
                          @click="getDetail"
                        >
                          <v-icon small>mdi-refresh</v-icon>
                        </v-btn>
                      </template>
                      <span>refresh page</span>
                    </v-tooltip>
                  </p>
                </v-toolbar-title>
              </div>
              <div class="actionButton">
                <div v-if="permission.update_perm" class="d-flex">
                  <div v-if="!isEdit" class="d-flex">
                    <!-- <div
                      v-if="
                        form.employee.id == getUserProfile.employee.id ||
                        form.created_by == getUserProfile.employee.id
                      "
                    >
                      <v-btn
                        v-if="form.status_request.id === 0"
                        type="submit"
                        rounded
                        elevation="0"
                        color="primary"
                        class="white--text font-weight-bold ma-2"
                        style="font-size: 12px"
                        :loading="loading"
                      >
                        Submit
                      </v-btn>
                    </div>
                    <div v-if="isAllowApprove">
                      <v-dialog
                        v-if="form.status_request.id === 1"
                        v-model="approveDialog"
                        width="500"
                        persistent
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            type="button"
                            rounded
                            elevation="0"
                            color="green"
                            class="white--text font-weight-bold ma-2"
                            style="font-size: 12px"
                            :loading="loading"
                          >
                            Approve
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title class="text-h5 grey lighten-2">
                            Form Persetujuan
                          </v-card-title>

                          <v-card-text
                            class="d-flex justify-center align-center"
                          >
                            <v-col
                              md="12"
                              style="padding: 0; position: relative; top: 20px"
                            >
                              <div style="padding: 0 10px">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                                      margin: 0;
                                      font-size: 12px;
                                      font-weight: bold;
                                      color: rgba(0, 0, 0, 0.5);
                                    "
                                  >
                                    Catatan
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0">
                                  <v-textarea
                                    outlined
                                    v-model="form.confirmed_note"
                                  />
                                </v-col>
                              </div>
                            </v-col>
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-btn
                              color="error"
                              text
                              outlined
                              @click="approveDialog = false"
                              :loading="loading"
                            >
                              Tutup
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                              @click.prevent="confirmOvertime()"
                              color="primary"
                              type="button"
                              text
                              outlined
                              :loading="loading"
                            >
                              Approve
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <v-dialog
                        v-if="form.status_request.id === 1"
                        v-model="rejectDialog"
                        width="500"
                        persistent
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            type="button"
                            rounded
                            elevation="0"
                            color="red"
                            class="white--text font-weight-bold ma-2"
                            style="font-size: 12px"
                            :loading="loading"
                          >
                            Reject
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title class="text-h5 grey lighten-2">
                            Form Penolakan
                          </v-card-title>

                          <v-card-text
                            class="d-flex justify-center align-center"
                          >
                            <v-col
                              md="12"
                              style="padding: 0; position: relative; top: 20px"
                            >
                              <div style="padding: 0 10px">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                                      margin: 0;
                                      font-size: 12px;
                                      font-weight: bold;
                                      color: rgba(0, 0, 0, 0.5);
                                    "
                                  >
                                    Alasan penolakan
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0">
                                  <v-textarea
                                    outlined
                                    v-model="form.cancel_note"
                                  />
                                </v-col>
                              </div>
                            </v-col>
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-btn
                              color="error"
                              text
                              outlined
                              @click="rejectDialog = false"
                              :loading="loading"
                            >
                              Tutup
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                              @click.prevent="rejectOvertime()"
                              color="primary"
                              type="button"
                              text
                              outlined
                              :loading="loading"
                            >
                              Tolak pengajuan
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div> -->
                    <div
                      v-if="
                        form.employee.id == getUserProfile.employee.id ||
                        form.created_by == getUserProfile.employee.id
                      "
                    >
                      <v-btn
                        v-if="form.status_request.id == 0"
                        @click="openCancelDialog"
                        type="button"
                        rounded
                        elevation="0"
                        color="red"
                        class="white--text font-weight-bold ma-2"
                        style="font-size: 12px"
                        :loading="loading"
                      >
                        Cancel
                      </v-btn>
                    </div>

                    <div
                      v-if="
                        getUserProfile.level.find(
                          ({ id }) => id == appLevel.adminMaster
                        ) !== undefined
                      "
                    >
                      <!-- <v-btn
                        v-if="
                          form.status_request.id === -2 ||
                          form.status_request.id === -1
                        "
                        @click.prevent="setToDraft"
                        type="button"
                        rounded
                        elevation="0"
                        color="primary"
                        class="white--text font-weight-bold ma-2"
                        style="font-size: 12px"
                        :loading="loading"
                      >
                        Set Draft
                      </v-btn> -->
                      <v-btn
                        v-if="
                          form.status_request.id === 2 ||
                          form.status_request.id === 3
                        "
                        @click="openCancelDialog"
                        type="button"
                        rounded
                        elevation="0"
                        color="red"
                        class="white--text font-weight-bold ma-2"
                        style="font-size: 12px"
                        :loading="loading"
                      >
                        Cancel
                      </v-btn>
                    </div>
                    <v-btn
                      type="button"
                      rounded
                      elevation="0"
                      color="black"
                      class="black--text font-weight-bold ma-2"
                      style="font-size: 12px"
                      @click.prevent="closeDetail"
                      :loading="loading"
                      outlined
                    >
                      <v-icon small>mdi-chevron-left</v-icon>
                      Kembali
                    </v-btn>
                  </div>

                  <div v-else class="d-flex">
                    <v-btn
                      @click="submitHeader"
                      type="button"
                      outlined
                      elevation="0"
                      color="success"
                      class="white--text font-weight-bold ma-2"
                      style="font-size: 12px"
                      rounded
                      :loading="loading"
                    >
                      <v-icon small>mdi-save</v-icon>
                      Simpan
                    </v-btn>
                    <v-btn
                      @click="cancelHeader"
                      outlined
                      type="button"
                      elevation="0"
                      color="error"
                      class="white--text font-weight-bold ma-2"
                      rounded
                      style="font-size: 12px; margin-left: 10px"
                      :loading="loading"
                    >
                      Batal
                    </v-btn>
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-center align-center"
                :style="`position:absolute; right:0; top:52px; ${statusColor(
                  form.status_request.id
                )} padding: 5px 30px; min-width: 200px;`"
              >
                <p
                  style="
                    margin: 0;
                    font-size: 16px;
                    font-weight: bolder;
                    text-transform: uppercase;
                  "
                >
                  {{ form.status_request.name }}
                </p>
              </div>
            </v-card>

            <v-card elevation="0">
              <v-toolbar-title
                style="padding: 0; text-align: center; height: 30px"
              >
                <!-- <p
              v-if="detail.request_no !== null"
              style="font-size: 20px; margin: 0; font-weight: bold; color:indigo;position:relative;"
            >
              {{ detail.request_no }}
            </p> -->

                <div
                  class="d-flex justify-start align-center"
                  style="position: absolute; right: 0; left: 0; top: -20px"
                >
                  <p style="font-size: 12px; margin: 0 15px">
                    Tgl. Buat :
                    {{ form.created_at }}/{{ form.created_by_name }}
                  </p>
                  <p style="font-size: 12px; margin: 0 15px">
                    Tgl. Submit :
                    {{ form.submited_at }}/{{ form.submited_by_name }}
                  </p>
                  <p style="font-size: 12px; margin: 0 0 0 15px">
                    Tgl. Terima :
                    {{ convertDate(form.confirmed_at) }}/{{
                      form.confirmed_by_name
                    }}
                  </p>
                </div>

                <div
                  v-if="
                    form.status_request.id == 0 ||
                    getUserProfile.level.find(
                      ({ id }) =>
                        id == appLevel.administrator ||
                        id == appLevel.adminMaster
                    ) !== undefined
                  "
                  class="d-flex"
                  style="position: absolute; right: 0; top: 15px"
                >
                  <v-btn
                    v-if="!isEdit"
                    @click.prevent="editHeader()"
                    type="button"
                    text
                    elevation="0"
                    color="primary"
                    class="white--text font-weight-bold"
                    style="font-size: 12px"
                    :loading="loading"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                    Edit Header
                  </v-btn>
                </div>
              </v-toolbar-title>
              <v-row style="padding: 15px" no-gutters>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        No. Pengajuan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        :value="form.request_no"
                        append-icon=""
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        No. Document
                        <v-tooltip right color="info">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="openHeader()"
                              style="cursor: pointer"
                              x-small
                              color="primary"
                            >
                              mdi-open-in-new
                            </v-icon>
                          </template>
                          <span>Lihat detail Header</span>
                        </v-tooltip>
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        :value="
                          form.overtime_document != null
                            ? form.overtime_document.document_no
                            : ''
                        "
                        append-icon=""
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Perusahaan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0; margin-top: 4px">
                      <!-- <v-autocomplete
                    :items="getDropdownPlant || []"
                    item-text="name"
                    item-value="id"
                    return-object
                    dense
                    style="margin: 0"
                    readonly
                    append-icon=""
                    v-model="form.company.id"
                  ></v-autocomplete> -->
                      <v-text-field dense :value="form.company.name" readonly />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="4" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Karyawan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0; margin-top: 6px">
                      <v-text-field
                        dense
                        :value="form.employee.name"
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        NIK
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        :value="form.employee.nik"
                        append-icon=""
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col>
                <!-- <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Department/ Section
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0; margin-top: 4px">
                      <v-autocomplete
                    v-if="form.department !== null"
                    :items="dropdown.department"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    style="margin: 0"
                    v-model="form.department.unit.id"
                    readonly
                    append-icon=""
                  ></v-autocomplete>
                      <v-text-field dense readonly />
                    </v-col>
                  </div>
                </v-col> -->
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Tanggal
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        v-if="!isEdit"
                        dense
                        :value="form.date"
                        append-icon=""
                        readonly
                      />
                      <v-text-field
                        v-else
                        dense
                        v-model="form.date"
                        @change="dateWatcher"
                        append-icon=""
                        outlined
                        clearable
                        type="date"
                        name="date"
                        step="1"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Shift
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        v-if="!isEdit"
                        dense
                        :value="form.shift != null ? form.shift.name : '-'"
                        append-icon=""
                        readonly
                      />
                      <v-autocomplete
                        v-else
                        v-model="form.shift"
                        :items="dropdown.shift"
                        @change="shiftWatcher"
                        item-text="name"
                        item-value="id"
                        return-object
                        outlined
                        dense
                        style="margin: 0"
                        clearable
                        :disabled="form.date == null"
                      >
                      </v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Waktu mulai yang diajukan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        v-if="!isEdit"
                        dense
                        :value="`${convertDate(form.request_start_time)} ${
                          form.shift != null ? '(' + form.shift.name + ')' : ''
                        }`"
                        append-icon=""
                        readonly
                      />
                      <v-text-field
                        v-else
                        dense
                        v-model="form.request_start_time"
                        append-icon=""
                        outlined
                        @change="startWatcher"
                        clearable
                        type="datetime-local"
                        name="datetime-local"
                        step="1"
                        :disabled="form.date == null"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Waktu berakhir yang diajukan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        v-if="!isEdit"
                        dense
                        :value="`${convertDate(form.request_end_time)} ${
                          form.shift != null ? '(' + form.shift.name + ')' : ''
                        }`"
                        append-icon=""
                        readonly
                      />
                      <v-text-field
                        v-else
                        dense
                        v-model="form.request_end_time"
                        append-icon=""
                        outlined
                        @change="endWatcher"
                        clearable
                        type="datetime-local"
                        name="datetime-local"
                        step="1"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Waktu mulai aktual
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        :value="convertDate(form.start_time)"
                        append-icon=""
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Waktu berakhir aktual
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        :value="convertDate(form.end_time)"
                        append-icon=""
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col>

                <v-col cols="12" md="3" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Total waktu lembur (jam)
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="padding: 0; position: "
                    >
                      <v-text-field dense :value="form.total_hour" readonly />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="3" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Pembulatan
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="padding: 0; position: "
                    >
                      <v-text-field
                        dense
                        :value="form.total_hour_rounding"
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col>

                <v-col
                  cols="6"
                  style="
                    padding: 0 0 0 10px;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <v-col
                    cols="6"
                    style="
                      padding: 0;
                      justify-content: flex-start;
                      align-items: center;
                      display: flex;
                    "
                  >
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Dipotong istirahat ?
                    </p>
                    <v-checkbox
                      v-model="form.is_break"
                      :false-value="false"
                      :true-value="true"
                      :readonly="!isEdit"
                      style="margin: 0 10px; height: 25px; padding: 0"
                    ></v-checkbox>
                  </v-col>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Alasan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-textarea
                        dense
                        rows="3"
                        auto-grow
                        :value="form.reason"
                        readonly
                        outlined
                      />
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </v-card>
            <v-divider> </v-divider>
            <v-card
              v-if="
                form.approval_matrix !== undefined &&
                form.approval_matrix !== null
              "
              flat
              class="mx-auto"
              style="padding: 10px 25px; margin-top: 1px; margin-bottom: 10px"
            >
              <v-toolbar-title class="overline">
                <p
                  style="
                    font-size: 16px;
                    margin: 0;
                    font-weight: bold;
                    color: indigo;
                    margin-bottom: 10px;
                  "
                >
                  Approval Matrix
                </p>
              </v-toolbar-title>
              <v-data-table
                v-if="form.approval_matrix !== null"
                mobile-breakpoint="0"
                :headers="approvalHeaders"
                :items="form.approval_matrix"
                class="approvalMatrix elevation-1 mx-auto"
                :items-per-page="itemsPerPage"
                :server-items-length="totalSequenceData"
                hide-default-footer
              >
                <template v-slot:[`item.approver_user`]="{ item }">
                  <div @click="rowClick(item)" style="cursor: pointer">
                    <v-chip
                      label
                      small
                      style="
                        margin-right: 5px;
                        padding: 0 3px;
                        cursor: pointer;
                        border: 1px solid rgba(0, 0, 0, 0.5);
                      "
                      v-for="(item, index) in item.approver_user"
                      :key="index"
                    >
                      {{ item.user.name }}
                    </v-chip>
                  </div>
                </template>
                <template v-slot:[`item.approved`]="{ item }">
                  <div
                    v-for="(items, index) in item.approver_user"
                    :key="index"
                  >
                    <v-chip
                      v-if="items.status === 1"
                      label
                      small
                      style="
                        margin-right: 5px;
                        padding: 0 3px;
                        cursor: pointer;
                        border: 1px solid rgba(0, 0, 0, 0.5);
                      "
                    >
                      {{ items.user.name }}
                    </v-chip>
                  </div>
                </template>
                <template v-slot:[`item.rejected`]="{ item }">
                  <div
                    v-for="(items, index) in item.approver_user"
                    :key="index"
                  >
                    <v-chip
                      v-if="items.status === -1"
                      label
                      small
                      style="
                        margin-right: 5px;
                        padding: 0 3px;
                        cursor: pointer;
                        border: 1px solid rgba(0, 0, 0, 0.5);
                      "
                    >
                      {{ items.user.name }}
                    </v-chip>
                  </div>
                </template>
              </v-data-table>
            </v-card>
            <!-- <div
              v-if="
                getUserProfile.level.find(
                  ({ id }) =>
                    id === appLevel.administrator || id === appLevel.adminMaster
                ) !== undefined ||
                form.employee.id == getUserProfile.employee.id ||
                form.created_by == getUserProfile.employee.id
              "
            >
              <div
                style="text-align: left; padding-left: 5px"
                v-if="
                  form.status_request.id == 1 || form.status_request.id == -2
                "
              >
                <v-btn
                  color="grey"
                  tile
                  dark
                  small
                  dense
                  style="font-size: 10px; width: 100px"
                  @click="fetch"
                  :loading="loading"
                >
                  <span>Fetch Matrix</span>
                </v-btn>
              </div>
            </div> -->
          </v-form>
        </v-container>
        <div
          v-else
          class="d-flex justify-center align-center"
          style="width: 100%; height: 80vh; position: relative"
        >
          <v-btn
            type="button"
            rounded
            elevation="0"
            color="red"
            class="white--text font-weight-bold ma-2"
            style="font-size: 12px; position: absolute; top: 10px; left: 10px"
            @click.prevent="close"
            :loading="loading"
            outlined
          >
            <v-icon small>mdi-chevron-left</v-icon>
            Kembali
          </v-btn>
          <p>Mohon tunggu...</p>
        </div>
      </div>
    </div>

    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      YOU CANNOT ACCESS THIS PAGE
    </v-card>

    <v-dialog v-if="form != null" v-model="cancelDialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Form Pembatalan
        </v-card-title>

        <v-card-text class="d-flex justify-center align-center">
          <v-col md="12" style="padding: 0; position: relative; top: 20px">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Alasan pembatalan
                  {{ form.status_request.id == 1 ? '(HRD)' : '' }}
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-textarea v-model="form.cancel_reason" outlined />
              </v-col>
            </div>
          </v-col>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="error"
            text
            outlined
            @click="closeCancelDialog"
            :loading="loading"
          >
            Tutup
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click.prevent="
              form.status_request.id === 1 ? hrCancel() : selfCancel()
            "
            color="primary"
            type="button"
            text
            outlined
            :loading="loading"
          >
            Batalkan pengajuan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
import moment from 'moment'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'leavesubmission',
  components: {},
  data() {
    return {
      config: require('../../../services/config.json')[env],
      hrsApi: buildType.apiURL('hrs'),
      eSanqua: buildType.apiURL('esanqua'),
      oAuthApi: buildType.apiURL('oauth'),
      build: process.env.VUE_APP_BUILD_TYPE,
      permission: null,
      appLevel: null,
      userLevel: null,
      isLoadingPage: true,
      dialog: false,
      approveDialog: false,
      rejectDialog: false,
      cancelDialog: false,
      loading: false,
      isEdit: false,
      form: null,
      wWidth: window.innerWidth,
      rules: {
        nameRules: [],
        companyRules: [],
        positionRules: [],
        departmentRules: []
      },
      dropdown: {
        shift: []
      },
      itemsPerPage: 10,
      totalSequenceData: 1,
      options: {},

      matrix: [],
      isAllowApprove: false,
      imgSrc: '',
      approvalHeaders: [
        { text: 'Sequence', value: 'sequence', align: 'left', width: '5%' },
        { text: 'Doc. No', value: 'document_no', align: 'left', width: '10%' },
        { text: 'Model', value: 'table_name', align: 'center', width: '10%' },
        { text: 'User', value: 'approver_user', align: 'center', width: '20%' },
        {
          text: 'Min. Approver',
          value: 'min_approver',
          align: 'center',
          width: '5%'
        },
        {
          text: 'Approved by',
          value: 'approved',
          align: 'center',
          width: '10%'
        },
        {
          text: 'Rejected by',
          value: 'rejected',
          align: 'center',
          width: '10%'
        },
        {
          text: 'Created at',
          value: 'created_at',
          align: 'center',
          width: '15%'
        },
        {
          text: 'Update at',
          value: 'updated_at',
          align: 'center',
          width: '15%'
        }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getDropdownPlant'])
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.appLevel = this.config.application.hr.userLevel
    this.appLevel.administrator =
      this.config.application.all.userLevel.administrator
    this.getDataMenu()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapActions(['menu_access', 'getMenuId']),
    ...mapMutations(['']),
    openCancelDialog() {
      setTimeout(() => {
        this.cancelDialog = true
      }, 300)
    },
    closeCancelDialog() {
      setTimeout(() => {
        this.cancelDialog = false
      }, 300)
    },
    async dropdownShift() {
      const url = `${this.hrsApi}master/universal/shift/dropdown?filter=[{"company_id":${this.form.company.id}}]`
      await axios
        .get(url)
        .then((res) => {
          console.log('shift>>>', res)
          if (res.data.status_code === '00') {
            return (this.dropdown.shift = res.data.data)
          }
          return (this.dropdown.shift = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.shift = [])
        })
    },
    shiftWatcher(val) {
      console.log(this.form.date)
      console.log(
        moment(this.form.date).subtract(1, 'days').format('YYYY-MM-YY')
      )

      if (this.form != null && this.form.shift != null) {
        var start = ''
        var end = this.form.date + 'T' + val.end_time
        if (val.start_time >= '20:00:00' && val.start_time <= '23:59:59') {
          var newDate = moment(this.form.date)
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
          start = newDate + 'T' + val.start_time
        } else {
          start = this.form.date + 'T' + val.start_time
        }

        this.form.request_start_time = start
        this.form.request_end_time = end

        if (
          this.form.request_start_time !== null &&
          this.form.request_end_time !== null
        ) {
          this.getDifferencies()
        }
      } else {
        this.form.request_start_time = null
        this.form.request_end_time = null
      }
    },
    dateWatcher(val) {
      this.form.shift = null
      this.form.request_start_time = null
      this.form.request_end_time = null
    },
    startWatcher(val) {
      if (this.form !== null) {
        if (
          this.form.request_start_time !== null &&
          this.form.request_end_time !== null
        ) {
          this.getDifferencies()
        }
      }
    },
    endWatcher(val) {
      if (this.form !== null) {
        if (
          this.form.request_start_time !== null &&
          this.form.request_end_time !== null
        ) {
          this.getDifferencies()
        }
      }
    },
    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      console.log('Path>>>>>', routeModule)
      await this.getMenuId({
        keyword: '',
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id == this.config.application.hr.applicationId ||
                application.id == this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null) {
              if (
                this.getUserProfile.level.find(
                  ({ id }) => id == this.appLevel.administrator
                ) !== undefined
              ) {
                this.resetPermission({
                  data: {
                    read_perm: 1,
                    create_perm: 1,
                    update_perm: 1,
                    delete_perm: 1
                  }
                })
              } else {
                this.checkMenuAccess([menuId, this.userLevel.id])
              }
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    async initDataForLayout() {
      await this.getDetail()
      this.authorityCheck()
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    // imgDialog() {
    //   this.imgSrc = this.form.photo
    //   setTimeout(() => {
    //     this.dialog = true
    //   }, 300)
    // },
    // imgError() {
    //   this.imgSrc =
    //     'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-jahsors8yollrrbjwsyx7.png'
    // },
    async getDetail() {
      this.loading = true
      let url = ''
      if (this.$route.query.redirect_from !== undefined) {
        url = `${this.hrsApi}employee/overtime_request/detail/${this.$route.params.id}?redirect_from=inappnotification`
      } else {
        url = `${this.hrsApi}employee/overtime_request/detail/${this.$route.params.id}`
      }
      await axios
        .get(url)
        .then((res) => {
          console.log('Detaill >>>', res)
          if (res.data.status_code !== '-99') {
            // res.data.data.
            this.form = res.data.data
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          return (this.loading = false)
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    closeDetail() {
      if (this.form !== null) {
        this.$refs.entryForm.reset()
      }

      if (this.prevRoute !== undefined) {
        if (this.prevRoute.path !== '/hr/overtime/document/detail/:id') {
          this.$router.push(this.prevRoute.path)
        } else {
          this.$router.push('/hr/overtime')
        }
      } else {
        this.$router.push('/hr/overtime')
      }
    },
    async submitOvertime() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/overtime_request/submit`, {
          // act: 'submit',
          id: this.$route.params.id
        })
        .then((res) => {
          console.log(res)
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async confirmOvertime() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/overtime_request/approve`, {
          id: this.$route.params.id,
          note: this.form.confirmed_note
        })
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            // this.loadLeaveApprovalNeeds()
            this.getDetail()
            this.approveDialog = false
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async rejectOvertime() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/overtime_request/reject`, {
          id: this.$route.params.id,
          note: this.form.rejected_note
        })
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            // this.loadLeaveApprovalNeeds()
            this.getDetail()
            this.rejectDialog = false
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async hrCancel() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/overtime_request/cancel`, {
          id: this.$route.params.id,
          note: this.form.cancel_note
        })
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail()
            this.closeCancelDialog()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async selfCancel() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/overtime_request/cancel`, {
          id: this.$route.params.id,
          note: this.form.cancel_note
        })
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail()
            this.closeCancelDialog()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async setToDraft() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/overtime_request/set_to_draft`, {
          id: this.$route.params.id
          // note: this.form.cancel_note
        })
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            // this.getDetail()
            this.fetch()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },

    openHeader() {
      if (this.form.overtime_document != null) {
        setTimeout(() => {
          window.open(
            `/hr/overtime/document/detail/${this.form.overtime_document.id}`,
            '_blank'
          )
        }, 200)
      }
    },
    fetch() {
      const form = {
        act: 'fetch_matrix',
        document_id: this.form.id,
        document_no: this.form.request_no,
        application_id: 21,
        table_name: 'tr_employeerequestovertimes'
      }
      this.loading = true
      axios
        .post(`${this.oAuthApi}approval_matrix/document/save`, form)
        .then((res) => {
          console.log('fetch>>', res)
          this.getDetail()
          this.loading = false
        })
        .catch((err) => {
          console.log('fetch error>>', err)
          this.loading = false
        })
    },
    editHeader() {
      console.log('edit overtime', this.form)
      this.dropdownShift()
      setTimeout(() => {
        this.isEdit = true
      }, 100)
      // this.setRabDetail(this.detail)
      // setTimeout(() => {
      //   this.$router.push('/e-catalogue/rab/submission')
      // }, 300)
    },
    async cancelHeader() {
      this.formHeader = null
      await this.getDetail()
      setTimeout(() => {
        this.isEdit = false
      }, 500)
    },
    submitHeader() {
      if (
        this.form.request_start_time === null ||
        this.form.request_end_time === null
      ) {
        return this.showMsgDialog(
          'warning',
          'Waktu mulai dan berakhir tidak boleh kosong',
          false
        )
      }
      const form = {
        act: 'update',
        id: this.form.id,
        employee_id: this.form.employee.id,
        company_id: this.form.company.id,
        company_name: this.form.company.name,
        request_start_time: this.form.request_start_time,
        request_end_time: this.form.request_end_time,
        total_hour: this.form.total_hour,
        total_hour_rounding: this.form.total_hour_rounding,
        is_break: this.form.is_break,
        shift_id: this.form.shift != null ? this.form.shift.id : null
      }
      // console.log(form)
      this.saveHeader(form)
    },
    async saveHeader(form) {
      console.log('Payload >>>>', form)
      this.isLoadingPage = true
      await axios
        .post(`${this.hrsApi}employee/overtime_request/save`, form)
        .then(async (res) => {
          console.log(res.data)
          this.isLoadingPage = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false).then(
              (response) => {
                this.getDetail()
              }
            )
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
          this.isEdit = false
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.isEdit = false
          this.isLoadingPage = false
        })
    },
    async authorityCheck() {
      if (this.form !== null) {
        if (this.form.status_request.id === 1) {
          // if (
          //   this.getUserProfile.level.find(({ id }) => id === '38') !== undefined
          // ) {

          if (this.form.approval_matrix !== null) {
            await axios
              .get(
                `${this.oAuthApi}approval_matrix/document/allow_user?document_id=${this.form.approval_matrix[0].document_id}&application_id=21`
              )
              .then((res) => {
                console.log('authorityCheck>>>', res)
                if (res.data.status_code === '00') {
                  // if (res.data.is_allow_approve !== 0) {
                  this.isAllowApprove = true
                  return null
                  // }
                }
                this.isAllowApprove = false
                return null
              })
              .catch((err) => {
                this.isAllowApprove = false
                console.log(err)
                return null
              })
          }
          // }
        }
      }
    },
    // convertDate(rax
    convertChangeWorkingDate(raw) {
      if (raw !== null) {
        // const arr = []
        // for (let i = 0; i < raw.length; i++) {
        const splitDate = raw.split('-')
        const y = splitDate[0]
        const m = splitDate[1].length === 2 ? splitDate[1] : '0' + splitDate[1]
        const d = splitDate[2].length === 2 ? splitDate[2] : '0' + splitDate[2]
        const newDate = `${d}/${m}/${y}`
        // arr.push(newDate)
        // }
        return newDate
      }
    },
    // rowClick(item) {
    //   console.log(item)
    // },

    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    },
    statusColor(val) {
      switch (val) {
        case -2:
          return 'color: red; background-color: #4e4e4e1f;'
        case -1:
          return 'color: red; background-color: #4e4e4e1f;'
        case 0:
          return 'color: black; background-color: #4e4e4e1f;'
        case 1:
          return 'color: rgb(138, 90, 0); background-color: rgba(255, 166, 0, 0.3);'
        case 2:
          return 'color: green; background-color: #4e4e4e1f;'
        // case 3:
        //   return 'color: #1976d2; background-color: #4e4e4e1f;'
        // case 4:
        //   return 'color: green; background-color: #4e4e4e1f;'
        // case 5:
        //   return 'color: red; background-color: #4e4e4e1f;'
        // case 6:
        //   return 'color: red; background-color: #4e4e4e1f;'
        default:
          return 'black'
      }
    },
    getDifferencies() {
      const timeStart = moment(this.form.request_start_time)
      const timeEnd = moment(this.form.request_end_time)

      var diff_s = timeEnd.diff(timeStart)
      var x = moment.duration(diff_s)
      var result = x / 60 / 60 / 1000

      this.form.total_hour = result.toFixed(2)

      // const rounding = Math.round(result * 100) / 100
      this.form.total_hour_rounding = Math.round(result)

      console.log(
        'perbedaan waktu >>>',
        result,
        result.toFixed(2),
        Math.round(result)
      )
    }
  }
}
</script>
<style lang="scss">
.actionButton {
  position: relative;
  display: flex;
  // width: 400px;
}
.approvalMatrix {
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
}
@media (max-width: 766px) {
  .actionButton {
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
  }
  .approvalMatrix {
    margin-bottom: 50px;
  }
}
</style>
